<script>
import {  mapMutations, mapGetters, mapActions } from 'vuex'
import arce from '../../../services/arce'
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			tour: 'myTourWizardArce',
			showing: 1,
			nextDisabled: false,
			information: {
				financialSponsor: false,
				ews: false,
				checklist: false,
				eeff: false,
				code: false,
			},
			selectedEEFF: null,
			sector: null,
		}
	},
	computed: {
		...mapGetters({
			currentArce: 'arceStatus/currentArce',
			statusStep: 'arceStatus/statusStep',
			stepsArce: 'arceStatus/stepsArce',
			groupStructure: 'arceStatus/groupStructure',
			currentStep: 'arceStatus/currentStep',
			arceRatingsSelected: 'arceRatings/selected',
			
			userId: 'auth/userId',
		})
	},
	
	methods: {
		prev() {
			let id = this.currentStep.sort
			if(this.showing) {
				if(this.showing > 1) {
					this.showing -= 1
				}
				return
			}
			this.showing = id-1 || 1
		},

		next() {
			this.showing += 1

			if(this.showing == 3) {
				setTimeout(() => {this.next()}, 500)
			}
			if(this.showing == 5) {
				setTimeout(() => {this.next()}, 500)
			}
			if(this.showing == 6) {
				setTimeout(() => {this.next()}, 500)
			}
			if(this.showing == 7) {
				this.nextDisabled = true
			}
		},
		
		close() {
			// this.hiddenSteps()
			// this.hideArceWizard()
			// this.resetWizardArce()
			this.hideArceWizard()
				this.resetCurrentArce()
				this.resetStepsArce()
				this.hiddenSteps()
		},
		validStep() {
			let step = this.currentStep
			if(this.showing) {
				let step_s = this.stepsArce.find(s => s.sort == this.showing)

				if(step_s) {
					step = step_s
				}
			}
			
			let data = this.currentArce[step.key]
			// if(!data) return false

			if(step.key == 'information') {
				let completed = true
				Object.keys(data).forEach(key => {
					if(!data[key]) {
						completed = false
					}
				});
				return completed
			}

			return true
		},
		
		updateInformation() {
			this.setInformation(this.information)
		},
		updateSector() {
			this.setSector(this.sector)
		},

		postpone() {
			let clientId = this.currentArce.client.id
			this.saveWizardArce(Object.assign({arce: {...this.currentArce}}, {userId: this.userId, status: 'postponed', steps: [...this.stepsArce]}))
			this.hideArceWizard()
			this.resetCurrentArce()
			this.resetStepsArce()
			this.$router.push({name: 'arce-client', params: {id: clientId}})
			this.hiddenSteps()
			this.showSteps('myTourClient')
		},
		async save() {
			// let service = arce
			// let folio = Date.now()
			// this.saveWizardArce(Object.assign({arce: {...this.currentArce}}, {userId: this.userId, status: 'finished', steps: [...this.stepsArce]}))
			this.hideArceWizard()
			this.arceRatingsSetWizardCompleted()
			
			this.$router.push({name: 'ArceRating', params: {id:  this.arceRatingsSelected.id}})
			console.log(this.arceRatingsSelected)
			// try {
			// 	let data = await service.save({
			// 		groupId: this.currentArce.groupStructure.id,
			// 		status: 'prepair',
			// 		folio: folio,
			// 		userId: this.userId,
			// 		children: [],
			// 		arce: {},
			// 		arceFill: {}
			// 	})
			// 	this.hideArceWizard()
			// 	this.resetCurrentArce()
			// 	this.resetStepsArce()
			// 	this.hiddenSteps()
			// 	this.$router.push({name: 'arce-details', params: {id: data._id}})
			// } catch (error) {
				
			// }
			
		},
		setEEFF(e) {
			if(e.target.checked) {
				this.nextDisabled = false
			}
		},	
		...mapMutations({
			showArceWizard: 'arceStatus/showArceWizard',
			hideArceWizard: 'arceStatus/hideArceWizard',
			markPassedStep: 'arceStatus/markPassedStep',
			setInformation: 'arceStatus/setInformation',
			resetCurrentArce: 'arceStatus/resetCurrentArce',
			resetStepsArce: 'arceStatus/resetStepsArce',
			setSector: 'arceStatus/setSector',
			setOperationType: 'arceStatus/setOperationType',
			resetWizardArce: 'arceStatus/resetWizardArce',
			arceRatingsSetWizardCompleted: 'arceRatings/setWizardCompleted'
		}),
		...mapActions({
			saveWizardArce: 'arceStatus/saveArce',
			getArce: 'arceStatus/getArce'
		})
	},

	mounted() {
		
		this.getArce({
			userId: this.userId,
			'arce.client.id': this.currentArce.client.id,
			status: 'postponed'
		}).then(data => {
			this.hiddenSteps()

			if(this.currentStep.key === 'information') {
				this.tour = 'myTourWizardArcePosponed'
			} else {
				this.tour = 'myTourWizardArce'
			}
			this.showSteps(this.tour)
		})
		
	}
}
</script>
<template>
	<div class="s-wizard">
		<div class="s-wizard__wrapper">
			<button class="s-wizard__close" @click="close">
				<svg-icon name="close" />
			</button>
			<div class="s-wizard__dialog">
				<!-- <pre>{{currentArce}}</pre> -->
				<!-- <pre>{{currentArce}}</pre> -->
				<div v-if="showing == 1">
					<div class="s-wizard__header border-bottom">
						<h1 class="text-center">ARCE Wizard</h1>
					</div>
					<p class="px-3">Select a local associated whit this client</p>
					<div class="s-wizard__body p-3">
						<p class="m-0 text-muted">Country</p>
						<select class="form-control">
							<option value="mexico">Mexico</option>
						</select>
					</div>
					<div class="s-wizard__body p-3">
						<p class="m-0 text-muted">Local Client</p>
						<select class="form-control">
							<option value="">Select client...</option>
							<option :value="currentArce.client.nombre">{{currentArce.client.nombre}}</option>
						</select>
					</div>
				</div>

				<div v-else-if="showing == 2">
					<div class="s-wizard__header border-bottom">
						<h1 class="text-center">ARCE Wizard</h1>
					</div>
					<div class="s-wizard__body p-3">
						<p>Select Model</p>
						<select class="form-control">
							<option>S&P 2012 model(Concurrent Ranking)</option>
							<option>S&P 2019 model(for simulation purposes only)</option>
						</select>
					</div>
				</div>

				<div v-if="[3].includes(showing)">
					<div class="s-wizard__header border-bottom">
						<h1 class="text-center">ARCE Wizard</h1>
						<p class="text-center">Select WordFlow</p>
					</div>
					<div class="d-flex flex-wrap justify-content-center p-3" data-v-step="w0-1">							
						<img class="m-auto gray loading" src="@/assets/simulator/loader.gif" alt="">
					</div>
				</div>
				<div v-if="showing == 4">
					<div class="s-wizard__header border-bottom">
						<h1 class="text-center">ARCE Wizard</h1>
						<p class="text-center">Select WordFlow</p>
					</div>
					<div class="d-flex flex-wrap justify-content-center p-3" data-v-step="w0-1">							
							<div class="col-12 col-md-6 col-lg-4 px-2">
								<div>
									<div class="s-option-icon" :class="{'selected': currentArce.operationType == 'fullrating'}" @click="setOperationType('fullrating')">
										<svg-icon name="favorite" />
									</div>
									<div class="text-center" style="font-size:15px;">
										<p class="m-0">Full Ranking Process</p>
										<p class="small m-0">Standalone Ranking + Overdays + Overmide + Rating Final</p>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-6 col-lg-4 px-2">
								<div>
									<div class="s-option-icon" :class="{'selected': currentArce.operationType == 'rating'}" @click="setOperationType('rating')">
										<svg-icon name="richtextindent" />
									</div>
									<div class="text-center">
										<p class="m-0">Overly Rating Process</p>
										<p class="small m-0">Overdays + Overmide + Rating Final</p>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-6 col-lg-4 px-2">
								<div>
									<div class="s-option-icon disabled">
										<svg-icon name="approval" />
									</div>
									<div class="text-center">
										<p class="m-0">Direct Rating Process</p>
										<p class="small m-0">Overmide + Rating Final</p>
									</div>
								</div>
							</div>
					</div>
				</div>
				<div v-else-if="currentArce.operationType == 'fullrating'">
					<div v-if="[5].includes(showing)">
						<div class="s-wizard__header border-bottom">
							<h1 class="text-center">ARCE Wizard</h1>
							<p class="text-center">Select WordFlow</p>
						</div>
						<div class="d-flex flex-wrap justify-content-center p-3" data-v-step="w0-1">							
							<img class="m-auto gray loading" src="@/assets/simulator/loader.gif" alt="">
						</div>
					</div>
					<div v-if="[6].includes(showing)">
						<div class="s-wizard__header border-bottom">
							<h1 class="text-center">ARCE Wizard</h1>
							<p class="text-center">Financial  statements selection</p>
						</div>
						<div class="d-flex flex-wrap justify-content-center p-3" data-v-step="w0-1">							
							<img class="m-auto gray loading" src="@/assets/simulator/loader.gif" alt="">
						</div>
					</div>
					<div v-if=" showing == 7">
						<div class="s-wizard__header border-bottom">
							<h1 class="text-center">Selección de EEFF</h1>
						</div>
						<div class="s-wizard__body p-0">
							<table class="s-table w-100">
								<thead>
									<tr>
										<th></th>									
										<th>
											Date
										</th>
										<th>
											Period
										</th>
										<th>
											Certification
										</th>
										<th>
											Type
										</th>
										<th>
											Valid for...
										</th>
										<th>
											Adjusted...
										</th>
										<th>
											id
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="n in 8">		
										<td><input type="radio" :value="n" v-model="selectedEEFF" @change="setEEFF"></td>								
										<td>
											12
										</td>
										<td>
											2019-12-31
										</td>
										<td>
											Auditado
										</td>
										<td>
											Individual
										</td>
										<td>
											Valido
										</td>
										<td>
											No Ajustado
										</td>
										<td>
											{{Date.now() }}{{n}}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				
				
				<div class="s-wizard__footer p-3 mt-3">
					<div class="">
						<div class=" w-75 mx-auto s-step">
							<div class="d-flex w-100 s-step__road">
								<span v-for="(step, index) in stepsArce"
								:key="`step-bar-${index}`"
								class="s-step__bar"
								:class="{
									's-step__bar--passed': showing > index +1,
									's-step__bar--current': showing == index + 1
								}"
								></span>
							</div>
							<div class="w-100 d-flex justify-content-between s-step__points">
								<span v-for="(step, index) in stepsArce" 
								:key="`step-modal-${index}`"
								class="s-step__item"
								:class="{
									's-step__item--passed': showing > index +1,
									's-step__item--current': showing == index + 1
								}">
									<svg-icon v-if="statusStep(step.key) == 'passed'" name="check" />
								</span>
							</div>
							
						</div>
					</div>
					<div class="d-flex justify-content-end">
						<!-- <button @click="prev" class="s-btn s-btn--secondary">
							Atras
						</button> -->
						
						<button  v-if="showing < 7" @click="next" class="s-btn" :class="{'s-btn--secondary': !validStep(), 's-btn--primary': validStep()}" :disabled="nextDisabled">
							Next
						</button>
						<button v-else @click="save" data-v-step="w5" class="s-btn s-btn--primary">
							Finish
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.disabled{
	background-color: #eee;
	cursor: not-allowed;
	&:hover{
		box-shadow: inset 0 0 0 1px rgb(119, 118, 118),
	}
}
.s-table{
	tbody,
	thead{
		tr{
			td,
			th{
				font-size: 0.7rem;
			}
		}
	}
}
.gray{
	filter: grayscale(1);
}
.loading{
	width: 50px;
}
</style>